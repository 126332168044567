import {useState} from 'react'
import './App.css';

const Footing = ({loc, setLoc}) => {
  const gotoLoc = (e, navloc) => {
      e.preventDefault();
      setLoc(navloc);
  }
  const isActiveLoc = (navloc) => {
      return navloc !== loc ? 'navvy-pill' : 'navvy-pill active';
  }
  return (
      <div className="site-footing">
          <div className="navvy">
              <a href="#home" className={isActiveLoc('home')} onClick={(e) => gotoLoc(e, 'home')}>
                  <i className="fa-sharp fa-solid fa-house fa-xl"></i>
              </a>
              <a href="#msg" className={isActiveLoc('msg')} onClick={(e) => gotoLoc(e, 'msg')}>
                  <i className="fa-regular fa-envelope fa-xl"></i>
              </a>
              <a href="#rules"  className={isActiveLoc('rules')} onClick={(e) => gotoLoc(e, 'rules')}>
                  <i className="fa-regular fa-book fa-xl"></i>
              </a>
              <a href="https://www.instagram.com/underprivilegedcc/" target="_blank" className="navvy-pill">
                  <i className="fa-brands fa-instagram fa-xl"></i>
              </a>
              <a href="#events" className={isActiveLoc('events')} onClick={(e) => gotoLoc(e, 'events')}>
                  <i className="fa-light fa-calendar-days fa-xl"></i>
              </a>  
          </div>
      <div className="site-footing-logo">Underprivileged</div>
   </div>
  )
}

const Home = (props) => {
  return (
  <div>
      <h1>Underprivileged Car Club</h1>
      <h2>San Joaquin County, California</h2>
      <div className="content">
          <p>
          Hello and welcome to underprivileged car club. The site is still a working progress, and will be launching sometime in the future. 
          </p>
          <p>A short little bio for you is we are just 3 people who enjoy cars, nothing specific. We just like to mod, ride, and hangout. We also all share a like mind to wanna give back to the community as well. So we intend to host some charity events in the future as well.</p>
          <p>If you are interested in joining the club. Click that little envelope below to reach out to us. Or visit us on instagram and drop a message there.</p>
      </div>
  </div>
  )
}

const Msg = (props) => {
  return (
      <div>
        <h1>Message Us</h1>
          <h2>Form not yet working</h2>
        <form id="contactForm">
          <div className="mb-3">
            <label className="form-label" htmlFor="name">Name</label>
            <input className="form-control" id="name" type="text" placeholder="Name" data-sb-validations="required" />
          </div>
           <div className="mb-3">
            <label className="form-label" htmlFor="emailAddress">Email Address</label>
            <input className="form-control" id="emailAddress" type="email" placeholder="Email Address" data-sb-validations="required, email" />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="message">Message</label>
            <textarea className="form-control" id="message" type="text" placeholder="Message" style={{height: "10rem"}} data-sb-validations="required" />
          </div>
          <div className="d-grid">
            <button className="btn btn-primary btn-lg" type="submit" disabled="disabled">Submit</button>
          </div> 
        </form>
    </div>
  );
}

const Rules = (props) => {
  return (<h1>Rules</h1>);
}

const Events = (props) => {
  return (<h1>Events</h1>);
}

const App = () => {
  const [loc, setLoc] = useState('home');
  
  return (
      <div>
          <div className="container">
              {loc === 'home' && <Home />}
              {loc === 'msg' && <Msg />}
              {loc === 'rules' && <Rules />}
              {loc === 'events' && <Events />}

          </div>
          <Footing loc={loc} setLoc={setLoc} />
      </div>
  )
}

export default App;
